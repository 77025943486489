import React from 'react';
import './App.css';
import { BrowserRouter, Route, Routes, Link, Navigate } from 'react-router-dom'
import BasicForm from './Component/BasicForm';
import Login from './Component/Login';
import Protected from './Component/Protected';
import { CssBaseline, Container, Paper, Box } from "@material-ui/core";
import { DataProvider } from './Component/context';
import { ToastContainer, toast } from 'react-toastify';

function App() {
  return (
    <div className="App">
  <ToastContainer />
      <DataProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Protected Component={BasicForm} />} />
            <Route path="/login" element={<Login />} />
          </Routes>
        </BrowserRouter>
      </DataProvider>
    </div>
  );
}

export default App;
