import React, { useRef } from 'react'
import { useDataContext } from './context';
import { PDFExport } from "@progress/kendo-react-pdf";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function FinalPage() {
    const { showDetail } = useDataContext();
    let fianlResults;
    const pdfExportComponent = useRef(null);

    const handleExportWithComponent = event => {
        pdfExportComponent.current.save();
        toast.success("Download Form successfully!");

    };

    const userDetail = JSON.parse(localStorage.getItem("data"));
    console.log("userDetail====", userDetail);

    if (showDetail) {
        console.log("showDetail?.data?.data?.list", showDetail?.data?.data);
        const jsonArray = JSON.parse(showDetail?.data?.data?.list);
        console.log("jsonArray====", jsonArray);
        const jsonFullArray = jsonArray.map((jsonString) => JSON.parse(jsonString));
        console.log("jsonFullArrayjsonFullArray", jsonFullArray);
        fianlResults = jsonFullArray
    }

    console.log("fianlResultsfianlResults", showDetail);


    return (
        <div style={{ width: "1280px", overflow: "auto" }}>
            {fianlResults ?
                <>
                    <button className='button-download' onClick={handleExportWithComponent}>Download</button>
                    <PDFExport ref={pdfExportComponent}>
                        <div className='border-[30px] border-[#f58634] min-h-[100vh]' >
                            <div className="flex justify-around">
                                <div className='grid md:grid-cols-3  gap-y-3 flex items-center'>
                                    <div className="flex justify-center">
                                        <img src="../../Images/Login.png" alt="Logo" width={"160px"} />
                                    </div>
                                    <div className='bg-[#2479c1] py-3 px-6'>
                                        <h6 className='text-[#fff] text-bold 2xl:text-[25px] xl:text-[20px]'>2024 : A YEAR OF BIG BREAKTHROUGHS</h6>
                                    </div>
                                    <div className='uppercase'>
                                        <h6><span className='text-[red]'>NAME:</span> {userDetail?.prefix} {userDetail?.first_name} {userDetail?.last_name}</h6>
                                    </div>
                                </div>
                            </div>

                            {/* Top Header End */}

                            {/* Top Goal Start */}


                            <div className='grid grid-cols-7 my-4 flex'>
                                <div className='flex justify-center'>
                                    <div>

                                        <div className='flex gap-2'>
                                            <p className="flex flex-col bg-[#ef8239] Shape justify-center text-[12px] w-[20px] p-3 font-bold text-[#000] h-[127px]">
                                                <span className='flex justify-center items-center'>H</span>
                                                <span className='flex justify-center items-center'>E</span>
                                                <span className='flex justify-center items-center'>A</span>
                                                <span className='flex justify-center items-center'>L</span>
                                                <span className='flex justify-center items-center'>T</span>
                                                <span className='flex justify-center items-center'>H</span>
                                            </p>
                                            <img src="../../Images/Final-Page/RuningMan.jpg" alt="Health" className='rounded-full w-[100px] h-[100px] ' />
                                        </div>
                                        <div>
                                            <ul className="list-[disc] ml-5 text-left text-[#000] font-medium">

                                                <div>{fianlResults[0]?.Health?.HealthFirst.length > 0 ? <li>{fianlResults[0]?.Health?.HealthFirst}</li> : <></>}</div>
                                                <div>{fianlResults[0]?.Health?.HealthSecond.length > 0 ? <li>{fianlResults[0]?.Health?.HealthSecond}</li> : <></>}</div>
                                                <div>{fianlResults[0]?.Health?.HealthThird.length > 0 ? <li>{fianlResults[0]?.Health?.HealthThird}</li> : <></>}</div>
                                                <div>{fianlResults[0]?.Health?.HealthFourth.length > 0 ? <li>{fianlResults[0]?.Health?.HealthFourth}</li> : <></>}</div>
                                                <div>{fianlResults[0]?.Health?.HealthFifth.length > 0 ? <li>{fianlResults[0]?.Health?.HealthFifth}</li> : <></>}</div>
                                                <div>{fianlResults[0]?.Health?.HealthSixth.length > 0 ? <li>{fianlResults[0]?.Health?.HealthSixth}</li> : <></>}</div>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div className='flex justify-center'>
                                    <div>
                                        <div className='flex gap-2'>
                                            <p className="flex flex-col bg-[#ef8239] Shape justify-center text-[12px] w-[20px] p-3 font-bold text-[#000] h-[127px]">
                                                <span className='flex justify-center items-center'>W</span>
                                                <span className='flex justify-center items-center'>E</span>
                                                <span className='flex justify-center items-center'>A</span>
                                                <span className='flex justify-center items-center'>L</span>
                                                <span className='flex justify-center items-center'>T</span>
                                                <span className='flex justify-center items-center'>H</span>
                                            </p>
                                            <img src="../../Images/Final-Page/Wealth.jpg" alt="Health" className='rounded-full w-[100px] h-[100px] ' />
                                        </div>
                                        <div>
                                            <ul className="list-[disc] ml-5 text-left text-[#000] font-medium">
                                                <div>{fianlResults[0]?.Wealth?.WealthFirst.length > 0 ? <li>{fianlResults[0]?.Wealth?.WealthFirst}</li> : <></>}</div>
                                                <div>{fianlResults[0]?.Wealth?.WealthSecond.length > 0 ? <li>{fianlResults[0]?.Wealth?.WealthSecond}</li> : <></>}</div>
                                                <div>{fianlResults[0]?.Wealth?.WealthThird.length > 0 ? <li>{fianlResults[0]?.Wealth?.WealthThird}</li> : <></>}</div>
                                                <div>{fianlResults[0]?.Wealth?.WealthFourth.length > 0 ? <li>{fianlResults[0]?.Wealth?.WealthFourth}</li> : <></>}</div>
                                                <div>{fianlResults[0]?.Wealth?.WealthFifth.length > 0 ? <li>{fianlResults[0]?.Wealth?.WealthFifth}</li> : <></>}</div>
                                                <div>{fianlResults[0]?.Wealth?.WealthSixth.length > 0 ? <li>{fianlResults[0]?.Wealth?.WealthSixth}</li> : <></>}</div>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div className='flex justify-center'>
                                    <div>
                                        <div className='flex gap-2'>
                                            <p className="flex flex-col bg-[#ef8239] Shape justify-center text-[12px] w-[20px] p-3 font-bold text-[#000] h-[127px]">
                                                <span className='flex justify-center items-center'>B</span>
                                                <span className='flex justify-center items-center'>U</span>
                                                <span className='flex justify-center items-center'>S</span>
                                                <span className='flex justify-center items-center'>I</span>
                                                <span className='flex justify-center items-center'>N</span>
                                                <span className='flex justify-center items-center'>E</span>
                                                <span className='flex justify-center items-center'>S</span>
                                                <span className='flex justify-center items-center'>S</span>
                                            </p>
                                            <img src="../../Images/Final-Page/Business.jpg" alt="Health" className='rounded-full w-[100px] h-[100px] ' />
                                        </div>
                                        <div>
                                            <ul className="list-[disc] ml-5 text-left text-[#000] font-medium">
                                            <div>{fianlResults[0]?.Business?.BusinessFirst.length > 0? <li>{fianlResults[0]?.Business?.BusinessFirst}</li> : <></>}</div>
                                            <div>{fianlResults[0]?.Business?.BusinessSecond.length > 0? <li>{fianlResults[0]?.Business?.BusinessSecond}</li> : <></>}</div>
                                            <div>{fianlResults[0]?.Business?.BusinessThird.length > 0? <li>{fianlResults[0]?.Business?.BusinessThird}</li> : <></>}</div>
                                            <div>{fianlResults[0]?.Business?.BusinessFourth.length > 0? <li>{fianlResults[0]?.Business?.BusinessFourth}</li> : <></>}</div>
                                            <div>{fianlResults[0]?.Business?.BusinessFifth.length > 0? <li>{fianlResults[0]?.Business?.BusinessFifth}</li> : <></>}</div>
                                            <div>{fianlResults[0]?.Business?.BusinessSixth.length > 0? <li>{fianlResults[0]?.Business?.BusinessSixth}</li> : <></>}</div>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div className='flex justify-center'>
                                    <div>
                                        <div className='flex gap-2'>
                                            <p className="flex flex-col bg-[#ef8239] Shape text-[12px] justify-center w-[20px] p-3 font-bold text-[#000] h-[127px]">
                                                <span className='flex justify-center items-center'>H</span>
                                                <span className='flex justify-center items-center'>A</span>
                                                <span className='flex justify-center items-center'>P</span>
                                                <span className='flex justify-center items-center'>P</span>
                                                <span className='flex justify-center items-center'>I</span>
                                                <span className='flex justify-center items-center'>N</span>
                                                <span className='flex justify-center items-center'>E</span>
                                                <span className='flex justify-center items-center'>S</span>
                                                <span className='flex justify-center items-center'>S</span>
                                            </p>
                                            <img src="../../Images/Final-Page/happiness.jpg" alt="Health" className='rounded-full w-[100px] h-[100px] ' />
                                        </div>
                                        <div>
                                            <ul className="list-[disc] ml-5 text-left text-[#000] font-medium">
                                            <div>{fianlResults[0]?.Happiness?.HappinessFirst.length > 0? <li>{fianlResults[0]?.Happiness?.HappinessFirst}</li> : <></>}</div>
                                            <div>{fianlResults[0]?.Happiness?.HappinessSecond.length > 0? <li>{fianlResults[0]?.Happiness?.HappinessSecond}</li> : <></>}</div>
                                            <div>{fianlResults[0]?.Happiness?.HappinessThird.length > 0? <li>{fianlResults[0]?.Happiness?.HappinessThird}</li> : <></>}</div>
                                            <div>{fianlResults[0]?.Happiness?.HappinessFourth.length > 0? <li>{fianlResults[0]?.Happiness?.HappinessFourth}</li> : <></>}</div>
                                            <div>{fianlResults[0]?.Happiness?.HappinessFifth.length > 0? <li>{fianlResults[0]?.Happiness?.HappinessFifth}</li> : <></>}</div>
                                            <div>{fianlResults[0]?.Happiness?.HappinessSixth.length > 0? <li>{fianlResults[0]?.Happiness?.HappinessSixth}</li> : <></>}</div>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div className='flex justify-center'>
                                    <div>
                                        <div className='flex gap-2'>
                                            <p className="flex flex-col bg-[#ef8239] Shape text-[12px] justify-center w-[20px] p-3 font-bold text-[#000] h-[127px]">
                                                <span className='flex justify-center items-center'>F</span>
                                                <span className='flex justify-center items-center'>A</span>
                                                <span className='flex justify-center items-center'>M</span>
                                                <span className='flex justify-center items-center'>I</span>
                                                <span className='flex justify-center items-center'>L</span>
                                                <span className='flex justify-center items-center'>Y</span>
                                            </p>
                                            <img src="../../Images/Final-Page/family.jpg" alt="Health" className='rounded-full w-[100px] h-[100px] ' />
                                        </div>
                                        <div>
                                            <ul className="list-[disc] ml-5 text-left text-[#000] font-medium">
                                            <div>{fianlResults[0]?.Family?.FamilyFirst.length > 0? <li>{fianlResults[0]?.Family?.FamilyFirst}</li> : <></>}</div>
                                            <div>{fianlResults[0]?.Family?.FamilySecond.length > 0? <li>{fianlResults[0]?.Family?.FamilySecond}</li> : <></>}</div>
                                            <div>{fianlResults[0]?.Family?.FamilyThird.length > 0? <li>{fianlResults[0]?.Family?.FamilyThird}</li> : <></>}</div>
                                            <div>{fianlResults[0]?.Family?.FamilyFourth.length > 0? <li>{fianlResults[0]?.Family?.FamilyFourth}</li> : <></>}</div>
                                            <div>{fianlResults[0]?.Family?.FamilyFifth.length > 0? <li>{fianlResults[0]?.Family?.FamilyFifth}</li> : <></>}</div>
                                            <div>{fianlResults[0]?.Family?.FamilySixth.length > 0? <li>{fianlResults[0]?.Family?.FamilySixth}</li> : <></>}</div>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div className='flex justify-center'>
                                    <div>
                                        <div className='flex gap-2'>
                                            <p className="flex flex-col bg-[#ef8239] Shape text-[12px] justify-center w-[20px] p-3 font-bold text-[#000] h-[127px]">
                                                <span className='flex justify-center items-center'>P</span>
                                                <span className='flex justify-center items-center'>A</span>
                                            </p>
                                            <img src="../../Images/Final-Page/joy.jpg" alt="Health" className='rounded-full w-[100px] h-[100px] ' />
                                        </div>
                                        <div>
                                            <ul className="list-[disc] ml-5 text-left text-[#000] font-medium">
                                                <div>{fianlResults[0]?.PROGRESSALLIANCE?.PROGRESSALLIANCEFirst.length > 0? <li>{fianlResults[0]?.PROGRESSALLIANCE?.PROGRESSALLIANCEFirst}</li> : <></>}</div>
                                                <div>{fianlResults[0]?.PROGRESSALLIANCE?.PROGRESSALLIANCESecond.length > 0? <li>{fianlResults[0]?.PROGRESSALLIANCE?.PROGRESSALLIANCESecond}</li> : <></>}</div>
                                                <div>{fianlResults[0]?.PROGRESSALLIANCE?.PROGRESSALLIANCEThird.length > 0? <li>{fianlResults[0]?.PROGRESSALLIANCE?.PROGRESSALLIANCEThird}</li> : <></>}</div>
                                                <div>{fianlResults[0]?.PROGRESSALLIANCE?.PROGRESSALLIANCEFourth.length > 0? <li>{fianlResults[0]?.PROGRESSALLIANCE?.PROGRESSALLIANCEFourth}</li> : <></>}</div>
                                                <div>{fianlResults[0]?.PROGRESSALLIANCE?.PROGRESSALLIANCEFifth.length > 0? <li>{fianlResults[0]?.PROGRESSALLIANCE?.PROGRESSALLIANCEFifth}</li> : <></>}</div>
                                                <div>{fianlResults[0]?.PROGRESSALLIANCE?.PROGRESSALLIANCESixth.length > 0? <li>{fianlResults[0]?.PROGRESSALLIANCE?.PROGRESSALLIANCESixth}</li> : <></>}</div>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div className='w-[156px] h-[192px] border border-[#000] flex justify-center'>
                                    <img src={showDetail?.data?.data?.profile} alt="User Image" className='w-[156px] h-[190px]' />
                                </div>

                            </div>

                            {/* Top Goal End */}

                            {/* Quarter Start */}

                            <div className='flex'>
                                <div className='w-[80%] '>
                                    <table className='w-[100%] '>
                                        <tr>
                                            <td colspan="6" className='text-left px-5 text-[#a11314] font-bold text-[20px]'>1 <sup>st</sup> QUARTER GOAL</td>
                                        </tr>
                                        <tr>
                                            <td colSpan="2" className='font-bold text-[20px] bg-[#f58634] text-[#fff]'>JANUARY</td>
                                            <td colSpan="2" className='font-bold text-[20px] bg-[#f58634] text-[#fff]'>FEBRUARY</td>
                                            <td colSpan="2" className='font-bold text-[20px] bg-[#f58634] text-[#fff]'>MARCH</td>
                                        </tr>
                                        <tr>
                                            <td className="bg-[#247ac0] text-[16px] font-medium text-[#fff] w-[100px]">MASTER STROKES</td>
                                            <td className="bg-[#247ac0] text-[16px] font-medium text-[#fff] w-[100px]">GOAL</td>
                                            <td className="bg-[#247ac0] text-[16px] font-medium text-[#fff] w-[100px]">MASTER STROKES</td>
                                            <td className="bg-[#247ac0] text-[16px] font-medium text-[#fff] w-[100px]">GOAL</td>
                                            <td className="bg-[#247ac0] text-[16px] font-medium text-[#fff] w-[100px]">MASTER STROKES</td>
                                            <td className="bg-[#247ac0] text-[16px] font-medium text-[#fff] w-[100px]">GOAL</td>
                                        </tr>


                                        {Array.from({ length: 5 }, (_, index) => (
                                            <tr>
                                                {console.log("fianlResults[1]", fianlResults[1]["Quarter 1"]?.months?.FEBRUARY?.GOAL)}
                                                <td className='text-[16px] text-[#000]'>{fianlResults[1]["Quarter 1"]?.months?.JANUARY?.MASTER_STROKES != undefined && fianlResults[1]["Quarter 1"]?.months?.JANUARY?.MASTER_STROKES[index]}</td>
                                                <td className='text-[16px] text-[#000]'>{fianlResults[1]["Quarter 1"]?.months?.JANUARY?.GOAL != undefined && fianlResults[1]["Quarter 1"]?.months?.JANUARY?.GOAL[index]}</td>
                                                <td className='text-[16px] text-[#000]'>{fianlResults[1]["Quarter 1"]?.months?.FEBRUARY?.MASTER_STROKES != undefined && fianlResults[1]["Quarter 1"]?.months?.FEBRUARY?.MASTER_STROKES[index]}</td>
                                                <td className='text-[16px] text-[#000]'>{fianlResults[1]["Quarter 1"]?.months?.FEBRUARY?.GOAL != undefined && fianlResults[1]["Quarter 1"]?.months?.FEBRUARY?.GOAL[index]}</td>
                                                <td className='text-[16px] text-[#000]'>{fianlResults[1]["Quarter 1"]?.months?.MARCH?.MASTER_STROKES != undefined && fianlResults[1]["Quarter 1"]?.months?.MARCH?.MASTER_STROKES[index]}</td>
                                                <td className='text-[16px] text-[#000]'>{fianlResults[1]["Quarter 1"]?.months?.MARCH?.GOAL != undefined && fianlResults[1]["Quarter 1"]?.months?.MARCH?.GOAL[index]}</td>
                                            </tr>

                                        ))}

                                    </table>
                                </div>

                                <div className='w-[10%]  border-y-[2px] border-[#000] border-r-[2px]'>
                                    <img src={showDetail?.data?.data?.image[0]} className='image-main'></img>
                                </div>
                                <div className='w-[10%]  border-y-[2px] border-[#000] border-r-[2px]'>
                                    <div className='h-[60%] d-flex'>
                                        <img src="../../Images/Final-Page/EVALUATE.png" alt="" />
                                    </div>
                                    <div className='h-[40%] flex' style={{alignItems:"center"}}>
                                        <p className=''>{fianlResults[1]["Quarter 1"]?.description}</p>
                                    </div>
                                </div>
                            </div>
                            <div className='flex'>
                                <div className='w-[80%]'>
                                    <table className='w-[100%]'>
                                        <tr>
                                            <td colspan="6" className='text-left px-5 text-[#a11314] font-bold text-[20px]'>2 <sup>nd</sup> QUARTER GOAL</td>
                                        </tr>
                                        <tr>
                                            <td colSpan="2" className='font-bold text-[20px] bg-[#f58634] text-[#fff]'>APRIL</td>
                                            <td colSpan="2" className='font-bold text-[20px] bg-[#f58634] text-[#fff]'>MAY</td>
                                            <td colSpan="2" className='font-bold text-[20px] bg-[#f58634] text-[#fff]'>JUNE</td>
                                        </tr>
                                        <tr>
                                            <td className="bg-[#247ac0] text-[16px] font-medium text-[#fff] w-[100px]">MASTER STROKES</td>
                                            <td className="bg-[#247ac0] text-[16px] font-medium text-[#fff] w-[100px]">GOAL</td>
                                            <td className="bg-[#247ac0] text-[16px] font-medium text-[#fff] w-[100px]">MASTER STROKES</td>
                                            <td className="bg-[#247ac0] text-[16px] font-medium text-[#fff] w-[100px]">GOAL</td>
                                            <td className="bg-[#247ac0] text-[16px] font-medium text-[#fff] w-[100px]">MASTER STROKES</td>
                                            <td className="bg-[#247ac0] text-[16px] font-medium text-[#fff] w-[100px]">GOAL</td>
                                        </tr>
                                        {Array.from({ length: 5 }, (_, index) => (
                                            <tr>
                                                <td className='text-[16px] text-[#000]'>{fianlResults[1]["Quarter 2"]?.months?.APRIL?.MASTER_STROKES != undefined && fianlResults[1]["Quarter 2"]?.months?.APRIL?.MASTER_STROKES[index]}</td>
                                                <td className='text-[16px] text-[#000]'>{fianlResults[1]["Quarter 2"]?.months?.APRIL?.GOAL != undefined && fianlResults[1]["Quarter 2"]?.months?.APRIL?.GOAL[index]}</td>
                                                <td className='text-[16px] text-[#000]'>{fianlResults[1]["Quarter 2"]?.months?.MAY?.MASTER_STROKES != undefined && fianlResults[1]["Quarter 2"]?.months?.MAY?.MASTER_STROKES[index]}</td>
                                                <td className='text-[16px] text-[#000]'>{fianlResults[1]["Quarter 2"]?.months?.MAY?.GOAL != undefined && fianlResults[1]["Quarter 2"]?.months?.MAY?.GOAL[index]}</td>
                                                <td className='text-[16px] text-[#000]'>{fianlResults[1]["Quarter 2"]?.months?.JUNE?.MASTER_STROKES != undefined && fianlResults[1]["Quarter 2"]?.months?.JUNE?.MASTER_STROKES[index]}</td>
                                                <td className='text-[16px] text-[#000]'>{fianlResults[1]["Quarter 2"]?.months?.JUNE?.GOAL != undefined && fianlResults[1]["Quarter 2"]?.months?.JUNE?.GOAL[index]}</td>
                                            </tr>

                                        ))}

                                    </table>
                                </div>

                                <div className='w-[10%] border-[#000] border-r-[2px]'>
                                    <img src={showDetail?.data?.data?.image[1]} className='image-main'></img>
                                </div>
                                <div className='w-[10%] border-[#000] border-r-[2px]'>
                                    <div className='h-[60%] d-flex'>
                                        <img src="../../Images/Final-Page/EVALUATE.png" alt="" />
                                    </div>
                                    <div className='h-[40%] flex' style={{alignItems:"center"}}>
                                        <p className=''>{fianlResults[1]["Quarter 2"]?.description}</p>
                                    </div>
                                </div>
                            </div>
                            <div className='flex'>
                                <div className='w-[80%]'>
                                    <table className='w-[100%]'>
                                        <tr>
                                            <td colspan="6" className='text-left px-5 text-[#a11314] font-bold text-[20px]'>3 <sup>rd</sup> QUARTER GOAL</td>
                                        </tr>
                                        <tr>
                                            <td colSpan="2" className='font-bold text-[20px] bg-[#f58634] text-[#fff]'>JULY</td>
                                            <td colSpan="2" className='font-bold text-[20px] bg-[#f58634] text-[#fff]'>AUGUST</td>
                                            <td colSpan="2" className='font-bold text-[20px] bg-[#f58634] text-[#fff]'>SEPTEMBER</td>
                                        </tr>
                                        <tr>
                                            <td className="bg-[#247ac0] text-[16px] font-medium text-[#fff] w-[100px]">MASTER STROKES</td>
                                            <td className="bg-[#247ac0] text-[16px] font-medium text-[#fff] w-[100px]">GOAL</td>
                                            <td className="bg-[#247ac0] text-[16px] font-medium text-[#fff] w-[100px]">MASTER STROKES</td>
                                            <td className="bg-[#247ac0] text-[16px] font-medium text-[#fff] w-[100px]">GOAL</td>
                                            <td className="bg-[#247ac0] text-[16px] font-medium text-[#fff] w-[100px]">MASTER STROKES</td>
                                            <td className="bg-[#247ac0] text-[16px] font-medium text-[#fff] w-[100px]">GOAL</td>
                                        </tr>
                                        {Array.from({ length: 5 }, (_, index) => (
                                            <tr>
                                                <td className='text-[16px] text-[#000]'>{fianlResults[1]["Quarter 3"]?.months?.JULY?.MASTER_STROKES != undefined && fianlResults[1]["Quarter 3"]?.months?.JULY?.MASTER_STROKES[index]}</td>
                                                <td className='text-[16px] text-[#000]'>{fianlResults[1]["Quarter 3"]?.months?.JULY?.GOAL != undefined && fianlResults[1]["Quarter 3"]?.months?.JULY?.GOAL[index]}</td>
                                                <td className='text-[16px] text-[#000]'>{fianlResults[1]["Quarter 3"]?.months?.AUGUST?.MASTER_STROKES != undefined && fianlResults[1]["Quarter 3"]?.months?.AUGUST?.MASTER_STROKES[index]}</td>
                                                <td className='text-[16px] text-[#000]'>{fianlResults[1]["Quarter 3"]?.months?.AUGUST?.GOAL != undefined && fianlResults[1]["Quarter 3"]?.months?.AUGUST?.GOAL[index]}</td>
                                                <td className='text-[16px] text-[#000]'>{fianlResults[1]["Quarter 3"]?.months?.SEPTEMBER?.MASTER_STROKES != undefined && fianlResults[1]["Quarter 3"]?.months?.SEPTEMBER?.MASTER_STROKES[index]}</td>
                                                <td className='text-[16px] text-[#000]'>{fianlResults[1]["Quarter 3"]?.months?.SEPTEMBER?.GOAL != undefined && fianlResults[1]["Quarter 3"]?.months?.SEPTEMBER?.GOAL[index]}</td>
                                            </tr>

                                        ))}
                                    </table>
                                </div>

                                <div className='w-[10%]  border-y-[2px] border-[#000] border-r-[2px]'>
                                    <img src={showDetail?.data?.data?.image[2]} className='image-main'></img>
                                </div>
                                <div className='w-[10%]  border-y-[2px] border-[#000] border-r-[2px]'>
                                    <div className='h-[60%] d-flex'>
                                        <img src="../../Images/Final-Page/EVALUATE.png" alt="" />
                                    </div>
                                    <div className='h-[40%] flex' style={{alignItems:"center"}}>
                                        <p className=''>{fianlResults[1]["Quarter 3"]?.description}</p>
                                    </div>
                                </div>
                            </div>
                            <div className='flex'>
                                <div className='w-[80%]'>
                                    <table className='w-[100%]'>
                                        <tr>
                                            <td colspan="6" className='text-left px-5 text-[#a11314] font-bold text-[20px]'>4 <sup>th</sup> QUARTER GOAL</td>
                                        </tr>
                                        <tr>
                                            <td colSpan="2" className='font-bold text-[20px] bg-[#f58634] text-[#fff]'>OCTOBER</td>
                                            <td colSpan="2" className='font-bold text-[20px] bg-[#f58634] text-[#fff]'>NOVEMBER</td>
                                            <td colSpan="2" className='font-bold text-[20px] bg-[#f58634] text-[#fff]'>DECEMBER</td>
                                        </tr>
                                        <tr>
                                            <td className="bg-[#247ac0] text-[16px] font-medium text-[#fff] w-[100px]">MASTER STROKES</td>
                                            <td className="bg-[#247ac0] text-[16px] font-medium text-[#fff] w-[100px]">GOAL</td>
                                            <td className="bg-[#247ac0] text-[16px] font-medium text-[#fff] w-[100px]">MASTER STROKES</td>
                                            <td className="bg-[#247ac0] text-[16px] font-medium text-[#fff] w-[100px]">GOAL</td>
                                            <td className="bg-[#247ac0] text-[16px] font-medium text-[#fff] w-[100px]">MASTER STROKES</td>
                                            <td className="bg-[#247ac0] text-[16px] font-medium text-[#fff] w-[100px]">GOAL</td>
                                        </tr>
                                        {Array.from({ length: 5 }, (_, index) => (
                                            <tr>
                                                <td className='text-[16px] text-[#000]'>{fianlResults[1]["Quarter 4"]?.months?.OCTOBER?.MASTER_STROKES != undefined && fianlResults[1]["Quarter 4"]?.months?.OCTOBER?.MASTER_STROKES[index]}</td>
                                                <td className='text-[16px] text-[#000]'>{fianlResults[1]["Quarter 4"]?.months?.OCTOBER?.GOAL != undefined && fianlResults[1]["Quarter 4"]?.months?.OCTOBER?.GOAL[index]}</td>
                                                <td className='text-[16px] text-[#000]'>{fianlResults[1]["Quarter 4"]?.months?.NOVEMBER?.MASTER_STROKES != undefined && fianlResults[1]["Quarter 4"]?.months?.NOVEMBER?.MASTER_STROKES[index]}</td>
                                                <td className='text-[16px] text-[#000]'>{fianlResults[1]["Quarter 4"]?.months?.NOVEMBER?.GOAL != undefined && fianlResults[1]["Quarter 4"]?.months?.NOVEMBER?.GOAL[index]}</td>
                                                <td className='text-[16px] text-[#000]'>{fianlResults[1]["Quarter 4"]?.months?.DECEMBER?.MASTER_STROKES != undefined && fianlResults[1]["Quarter 4"]?.months?.DECEMBER?.MASTER_STROKES[index]}</td>
                                                <td className='text-[16px] text-[#000]'>{fianlResults[1]["Quarter 4"]?.months?.DECEMBER?.GOAL != undefined && fianlResults[1]["Quarter 4"]?.months?.DECEMBER?.GOAL[index]}</td>
                                            </tr>

                                        ))}
                                    </table>
                                </div>

                                <div className='w-[10%] border-b-2 border-[#000] border-r-[2px]'>
                                    <img src={showDetail?.data?.data?.image[3]} className='image-main'></img>
                                </div>
                                <div className='w-[10%] border-b-2 border-[#000] border-r-[2px]'>
                                    <div className='h-[60%] d-flex'>
                                        <img src="../../Images/Final-Page/EVALUATE.png" alt="" />
                                    </div>
                                    <div className='h-[40%] flex' style={{alignItems:"center"}}>
                                        <p className=''>{fianlResults[1]["Quarter 4"]?.description}</p>
                                    </div>
                                </div>
                            </div>

                            {/* Quarter End */}

                        </div>
                        </PDFExport>
                </> : "loading"}

        </div>


    )
}
