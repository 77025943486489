import React, { useState, useEffect } from "react";
import {
  Typography,
  TextField,
  Button,
  Stepper,
  Step,
  StepLabel,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  useForm,
  Controller,
  FormProvider,
  useFormContext,
} from "react-hook-form";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { MenuItem, Select } from '@material-ui/core';
import FinalPage from "./FinalPage";


import { useDataContext } from './context';
import axios from "axios";
import { baseUrl } from "../basUrl";
let data = "";
let images = [];

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(1),
  },
}));

function getSteps() {
  return [
    "Basic information",
    "QUARTER GOAL",
  ];
}


const TopGoal = () => {
  const { control, setValue, getValues } = useFormContext();
  const { setProfileImg } = useDataContext()

  // State to hold task data
  const [tasks, setTasks] = useState({
    Health: {
      HealthFirst: '',
      HealthSecond: '',
      HealthThird: '',
      HealthFourth: '',
      HealthFifth: '',
      HealthSixth: '',
    },
    Wealth: {
      WealthFirst: '',
      WealthSecond: '',
      WealthThird: '',
      WealthFourth: '',
      WealthFifth: '',
      WealthSixth: '',
    },
    Business: {
      BusinessFirst: '',
      BusinessSecond: '',
      BusinessThird: '',
      BusinessFourth: '',
      BusinessFifth: '',
      BusinessSixth: '',
    },
    Happiness: {
      HappinessFirst: '',
      HappinessSecond: '',
      HappinessThird: '',
      HappinessFourth: '',
      HappinessFifth: '',
      HappinessSixth: '',
    },
    Family: {
      FamilyFirst: '',
      FamilySecond: '',
      FamilyThird: '',
      FamilyFourth: '',
      FamilyFifth: '',
      FamilySixth: '',
    },
    PROGRESSALLIANCE: {
      PROGRESSALLIANCEFirst: '',
      PROGRESSALLIANCESecond: '',
      PROGRESSALLIANCEThird: '',
      PROGRESSALLIANCEFourth: '',
      PROGRESSALLIANCEFifth: '',
      PROGRESSALLIANCESixth: '',
    },
  });

  console.log("tasks==>.", tasks);


  useEffect(() => {


  }, [tasks])

  const handleImageChanges = (e) => {
    const file = e.target.files[0];

    if (file) {
      // You can set the image file to the state here
      setProfileImg(file);

      // If you want to display the image preview, you can create a URL for the image
      // const imageUrl = URL.createObjectURL(file);
      // console.log(imageUrl);
    }
  };

  const handleTaskChange = (section, taskName, value) => {
    setTasks((prevTasks) => ({
      ...prevTasks,
      [section]: {
        ...prevTasks[section],
        [taskName]: value,
      },
    }));
  };

  useEffect(() => {
    // const UpdateData = localStorage.getItem("Form-Data API Response:")

    const mobileDetailApiEndpoint = `${baseUrl}/auth/form-details`;
    const mobileNumber = localStorage.getItem('MobileNumber')
    axios.get(`${mobileDetailApiEndpoint}/${mobileNumber}`)
      .then(response => {
        if (response.status == 200) {
          console.log("responseeeeeee", response);
          // localStorage.setItem("data", JSON.stringify(response?.data?.data?.list));
          const UpdateData = response?.data?.data?.list;
          setProfileImg(response?.data?.data?.profile)
          const storedDataObject = JSON.parse(UpdateData)[0];
          const ConvertDatanInJson = JSON.parse(storedDataObject)
          console.log("UpdateData", ConvertDatanInJson);

          if (ConvertDatanInJson) {
            // Set the initial state directly with the stored data
            setTasks(ConvertDatanInJson);
          }

        } else {
          console.log(response.data.msg);
        }
      })
      .catch(error => {
        console.error('Error GET Form-Data:', error);
      });

  }, []);

  // Convert tasks object to JSON string and set it in form state
  useEffect(() => {
    const tasksString = JSON.stringify(tasks);
    setValue('tasks', tasksString);
    console.log("tasksString", tasksString);
  }, [tasks, setValue]);

  const { setDataFromTopGoal } = useDataContext();

  useEffect(() => {
    const tasks = getValues('tasks');
    // Example: sending data when the component mounts
    setDataFromTopGoal(tasks);
  }, [tasks, setDataFromTopGoal]);

  return (
    <>
      <div className="px-10">
      <label className="text-[30px] flex">
              Choose Your Profile Picture
            </label>
            <p style={{ color:"red", textAlign:"left" }}>Image size limit: up to 200kb</p>
        <Controller
          control={control}
          name={`profile`}
          render={({ field }) => (
            <input
              type="file"
              accept="image/*"
              className="w-[100%] py-2 text-[20px]"
              onChange={handleImageChanges}
            />
          )}
        />
        
      </div>
      <div className="px-10">
      {Object.entries(tasks).map(([section, sectionTasks]) => (
          <div key={section}>
            <h1 className="text-[40px]">
              {section === 'PROGRESSALLIANCE' ? 'PROGRESS ALLIANCE' : section}
            </h1>
              <div className="grid md:grid-cols-2 gap-5">
            {Object.entries(sectionTasks).map(([taskName, taskValue]) => (
              <Controller
                key={taskName}
                control={control}
                name={`${section}.${taskName}`}
                render={({ field }) => (
                  <TextField
                    id={taskName}
                    label={taskName}
                    variant="outlined"
                    placeholder={`Enter Your ${taskName} Task`}
                    fullWidth
                    margin="normal"
                    value={taskValue}
                    onChange={(e) => {
                      field.onChange(e);
                      handleTaskChange(section, taskName, e.target.value);
                    }}
                  />
                )}
              />
            ))}
              </div>
          </div>
        ))}
      </div>
    </>
  );
};

const ContactForm = () => {
  const { control } = useFormContext();
  const { dataFromTopGoal } = useDataContext();
  const months = [
    'JANUARY', 'FEBRUARY', 'MARCH', 'APRIL', 'MAY', 'JUNE',
    'JULY', 'AUGUST', 'SEPTEMBER', 'OCTOBER', 'NOVEMBER', 'DECEMBER'
  ];

  const Customquarters = [
    ["JANUARY", "FEBRUARY", "MARCH"],
    ["APRIL", "MAY", "JUNE"],
    ["JULY", "AUGUST", "SEPTEMBER"],
    ["OCTOBER", "NOVEMBER", "DECEMBER"],
  ];

  const customQuarterStructure = Customquarters.reduce((acc, quarter, quarterIndex) => {
    const quarterName = `Quarter ${quarterIndex + 1}`;
    acc[quarterName] = {
      months: quarter.reduce((monthsAcc, month) => {
        monthsAcc[month] = {
          MASTER_STROKES: "",
          GOALS: "",
        };
        return monthsAcc;
      }, {}),
      description: "",
    };
    return acc;
  }, {});

  const [taskValues, setTaskValues] = useState({
    ...customQuarterStructure,
    images: [],
  });

    console.log("images", taskValues.images);

  const QuarterGoal = JSON.stringify(taskValues, (key, value) => (key === 'images' ? undefined : value), 2);

  useEffect(() => {
    // const UpdateData = localStorage.getItem("Form-Data API Response:")
    const mobileDetailApiEndpoint = `${baseUrl}/auth/form-details`;
    const mobileNumber = localStorage.getItem('MobileNumber');
    const data = localStorage.getItem('MobileNumber')
    axios.get(`${mobileDetailApiEndpoint}/${data}`)
      .then(response => {
        if (response.status == 200) {
          console.log("responseeeeeee", response);
          // localStorage.setItem("data", JSON.stringify(response?.data?.data?.list));
          const UpdateData = response?.data?.data?.list
          const storedDataObject = JSON.parse(UpdateData)[1];
          const ConvertDatanInJson = JSON.parse(storedDataObject)
          console.log("taskvalues....[]", ConvertDatanInJson);
          if (ConvertDatanInJson) {
            // Set the initial state directly with the stored data
            setTaskValues(ConvertDatanInJson);
          }

        } else {
          console.log(response.data.msg);
        }
      })
      .catch(error => {
        console.error('Error GET Form-Data:', error);
      });

  }, []);



  data = [
    dataFromTopGoal,
    QuarterGoal
  ]

  console.log("taskValues2", dataFromTopGoal);
  images = taskValues





  // Maintain a separate state for each month and part
  const [quarters, setQuarters] = useState(Array(months.length).fill(1));
  const [goals, setGoals] = useState(Array(months.length).fill(1));
  const [clickCounts, setClickCounts] = useState(Array(months.length).fill(0));

  const updateQuarter = (monthIndex) => {
    // Update the quarter every three months
    const newQuarter = Math.floor(monthIndex / 3) + 1;
    const newQuarters = [...quarters];
    newQuarters[monthIndex] = newQuarter;
    setQuarters(newQuarters);
  };

  const handlePlusClick = (setter, monthIndex) => {
    // Allow only 10 clicks for each month
    if (clickCounts[monthIndex] < 10) {
      setter((prev) => {
        const newState = [...prev];
        newState[monthIndex] = prev[monthIndex] + 1;
        return newState;
      });

      setClickCounts((prevCounts) => {
        const newCounts = [...prevCounts];
        newCounts[monthIndex] = prevCounts[monthIndex] + 1;
        return newCounts;
      });
    }
  };

  const renderTasks = (type, month, quarterName, monthIndex) => {
    const numTasks = type === 'MASTER_STROKES' ? quarters[monthIndex] : goals[monthIndex];
    const isImageUpload = (monthIndex + 1) % 3 === 0;
    const existingTasks = taskValues?.[quarterName]?.months?.[month]?.[type] || [];
    let StartIndex = existingTasks.length;
    StartIndex = StartIndex === 0 ? 1 : StartIndex;


    { console.log("hey", month, taskValues[quarterName].months[month][type]) }


    const tasks = Array.from({ length: Math.max(numTasks + StartIndex - 1, StartIndex) }, (_, index) => (

      <Controller
        key={index}
        control={control}
        name={`${quarterName}-${month}-${type}-${index + 1}`}
        render={({ field }) => { // Log the field object
          return (
            <TextField
              id={`${quarterName}${month}${type}${index + 1}`}
              label={month}
              variant="outlined"
              placeholder={`Enter Your ${index + 1} Task`}
              fullWidth
              margin="normal"
              {...field}
              value={taskValues[quarterName]?.months?.[month]?.[type]?.[index] || ''} // Ensure proper initialization
              onChange={(e) => {
                setTaskValues((prevTaskValues) => {
                  const updatedValues = {
                    ...prevTaskValues,
                    [quarterName]: {
                      ...prevTaskValues[quarterName],
                      months: {
                        ...prevTaskValues[quarterName]?.months,
                        [month]: {
                          ...prevTaskValues[quarterName]?.months?.[month],
                          [type]: [
                            ...(prevTaskValues[quarterName]?.months?.[month]?.[type] || []).slice(0, index),
                            e.target.value,
                            ...(prevTaskValues[quarterName]?.months?.[month]?.[type] || []).slice(index + 1),
                          ],
                        },
                      },
                    },
                  };
                  return updatedValues;
                });
                field.onChange(e);
              }}
            />
          );
        }}
      />
    ));




    if (isImageUpload) {
      tasks.push(
        <React.Fragment key={`imageUpload-description-${monthIndex}`}>
          {type === 'MASTER_STROKES' && (
            <>
            <p style={{ color:"red", textAlign:"left" }}>Image size limit: up to 200kb</p>
              <Controller
                control={control}
                name={`imageUpload-${quarterName}`}
                render={({ field }) => (
                  <input
                    type="file"
                    accept="image/*"
                    className="w-[100%] py-2 text-[20px]"
                    onChange={(e) => {
                      handleImageChange(quarterName, e.target.files);
                      field.onChange(e.target.files);
                    }}
                  />
                )}
              />
              <Controller
                control={control}
                name={`description-${quarterName}`}
                render={({ field }) => (
                  <textarea
                    rows={4}
                    className="w-[100%] p-2 mt-2 text-[20px] border border-[1px] border-[#797979] "
                    placeholder={`Enter Your Reward`}
                    {...field}
                    value={taskValues[quarterName].description}
                    onChange={(e) => {
                      setTaskValues((prevTaskValues) => {
                        const updatedValues = {
                          ...prevTaskValues,
                          [quarterName]: {
                            ...prevTaskValues[quarterName],
                            description: e.target.value,
                          },
                        };
                        return updatedValues;
                      });
                      field.onChange(e);
                    }}
                  />
                )}
              />
            </>
          )}
        </React.Fragment>
      );
    }

    return tasks;
  };

  const handleImageChange = (quarterName, files) => {
    setTaskValues((prevTaskValues) => {
      const quarterIndex = parseInt(quarterName.split(' ')[1]) - 1; // Extract quarter index
      console.log("quarterIndex", quarterIndex);

      // Create a deep copy of the previous task values
      const updatedValues = {
        ...prevTaskValues,
        [quarterName]: {
          ...prevTaskValues[quarterName],
          images: [...(prevTaskValues[quarterName]?.images || [])], // Use the existing images or initialize as an empty array
        },
      };

      console.log("updatedValues", updatedValues);

      // Update the images array for the current quarter
      updatedValues[quarterName].images[quarterIndex] = files.length > 0 ? [...files] : null;

      return updatedValues;
    });
  };


  return (
    <>
      {months.map((month, index) => {
        const currentQuarter = Math.floor(index / 3) + 1;
        const isFirstMonthOfQuarter = index % 3 === 0;
        const quarterName = `Quarter ${currentQuarter}`;

        return (
          <div key={month} className="px-10">
            {isFirstMonthOfQuarter && (
              <h1 className="text-[40px]">{currentQuarter} <sup>st</sup> QUARTER GOAL </h1>
            )}
            <h4 className="text-left underline">{month}</h4>
            <div className="grid md:grid-cols-2 grid-cols-1 gap-6">
              <div>
                <div className="flex items-center justify-between">
                  <h5 className="text-left">MASTER STROKES</h5>
                  <FontAwesomeIcon
                    icon={faPlus}
                    onClick={() => handlePlusClick(setQuarters, index)}
                    style={{ cursor: 'pointer' }}
                    disabled={clickCounts[index] >= 10}
                  />
                </div>
                {renderTasks('MASTER_STROKES', month, quarterName, index)}
              </div>
              <div>
                <div className="flex items-center justify-between">
                  <h5 className="text-left">GOAL</h5>
                  <FontAwesomeIcon
                    icon={faPlus}
                    onClick={() => handlePlusClick(setGoals, index)}
                    style={{ cursor: 'pointer' }}
                    disabled={clickCounts[index] >= 10}
                  />
                </div>
                {renderTasks('GOAL', month, quarterName, index)}
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};


function getStepContent(step) {
  switch (step) {

    case 0:
      return <TopGoal />;

    case 1:
      return <ContactForm />;

    default:
      return "unknown step";
  }
}

const LinaerStepper = () => {
  const classes = useStyles();
  const { profileImg } = useDataContext();

  console.log("profileImgprofileImg", profileImg);
  const methods = useForm({
    defaultValues: {
      HealthFirst: "",
      HealthSecond: "",
      HealthThird: "",
      WealthFirst: "",
      WealthSecond: "",
      WealthThird: "",
      BusinessFirst: "",
      BusinessSecond: "",
      BusinessThird: "",
      HappinessFirst: "",
      HappinessSecond: "",
      HappinessThird: "",
      FamilyFirst: "",
      FamilySecond: "",
      FamilyThird: "",
      JoyFirst: "",
      JoySecond: "",
      JoyThird: "",
    },
  });

  const [activeStep, setActiveStep] = useState(0);
  const [skippedSteps, setSkippedSteps] = useState([]);
  const { showDetail, setShowDetail } = useDataContext();
  const steps = getSteps();


  const isStepOptional = (step) => {
    return step === 1 || step === 2;
  };

  const isStepSkipped = (step) => {
    return skippedSteps.includes(step);
  };

  const handleNext = (data) => {
    console.log(data);
    if (activeStep == steps.length - 1) {
      fetch("https://jsonplaceholder.typicode.com/comments")
        .then((data) => data.json())
        .then((res) => {
          console.log(res);
          setActiveStep(activeStep + 1);
        });
    } else {
      setActiveStep(activeStep + 1);
      setSkippedSteps(
        skippedSteps.filter((skipItem) => skipItem !== activeStep)
      );
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const handleSkip = () => {
    if (!isStepSkipped(activeStep)) {
      setSkippedSteps([...skippedSteps, activeStep]);
    }
    setActiveStep(activeStep + 1);
  };


  const callApi = async () => {
    const MobileNumber = localStorage.getItem("MobileNumber");
    const formData = new FormData();
    // const list = JSON.stringify(data)
    const urlRegex = /^(http[s]?:\/\/)?(www\.)?([^\s$.?#].[^\s]*)$/;
    const resukltForLink = urlRegex.test(profileImg);

    console.log("resukltForLinkresukltForLinkresukltForLinkresukltForLink" , resukltForLink);
    if(!resukltForLink){
      formData.append('profile', profileImg);
    }


    // Include all data in FormData

    formData.append('mobile', MobileNumber);
    const abc = JSON.stringify(data)
    const json_data_1 = JSON.parse(abc);

    // Convert object back to a formatted JSON string without array brackets
    console.log("dataaaa", data);
    const combinedJsonString = JSON.stringify(data);


    formData.append('list', combinedJsonString);
    const datas = await axios.post(`${baseUrl}/auth/form`, formData).then(async (res) => {
      console.log("res", res);
      return res?.data?.data?.id
    }).catch((err) => {
      console.log(err)
    })

    console.log("datadatadata", datas);
    if (datas) {

      let allImages = [];

      // Iterate through each quarter
      for (const quarterKey in images) {
        if (images.hasOwnProperty(quarterKey) && images[quarterKey].images) {
          // Access images for the current quarter
          // console.log("images[quarterKey]", images[quarterKey].images.);
          const imagesForQuarter = images[quarterKey].images.map((res) => {
            allImages.push(res[0])
          });
        }
      }


      console.log("allImages", allImages);
      // console.log("");
      // const finalImages = images.length > 0 && images.images.flat()
      console.log("images.length", images.length, images);
      if (allImages.length > 0) {
        const formData = new FormData();
        for (let i = 0; i < allImages.length; i++) {
          formData.append('image', allImages[i]);
        }
        await axios.put(`${baseUrl}/auth/upload/${datas}`, formData).then(async (resp) => {

        }).catch((err) => {
          console.log(err);
        })
      }




      await axios.get(`${baseUrl}/auth/form-detail/${datas}`).then((res) => {
        console.log("resssss=====", res);
        setShowDetail(res)
      }).catch((err) => {
      })
    }

  }
  // const onSubmit = (data) => {
  //   console.log(data);
  // };
  return (
    <div style={{ overflowX: "hidden" }}>
      <Stepper alternativeLabel activeStep={activeStep}>
        {steps.map((step, index) => {
          const labelProps = {};
          const stepProps = {};
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step {...stepProps} key={index}>
              <StepLabel {...labelProps}>{step}</StepLabel>
            </Step>
          );
        })}
      </Stepper>

      {activeStep === steps.length ? (
        <div style={{ overflow: "auto" }}>
          <FinalPage />
        </div>
      ) : (
        <>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(handleNext)} className="py-3">
              {getStepContent(activeStep)}

              <Button
                className={classes.button}
                disabled={activeStep === 0}
                onClick={handleBack}
              >
                back
              </Button>
              {activeStep === steps.length - 1 ? <Button
                className={classes.button}
                variant="contained"
                color="primary"
                onClick={callApi}
                type="submit"
              >
                Finish
              </Button> : <Button
                className={classes.button}
                variant="contained"
                color="primary"
                // onClick={callApi()}
                type="submit"
              >
                Next
              </Button>}

            </form>
          </FormProvider>
        </>
      )}
    </div>
  );
};

export default LinaerStepper;