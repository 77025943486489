import React, { useEffect, useState } from 'react';
import OtpInput from 'react-otp-input';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { useDataContext } from './context';
import { baseUrl } from '../basUrl';

export default function Login() {
    const [mobileNumber, setMobileNumber] = useState('');
    const [validationError, setValidationError] = useState('');
    const [otp, setOtp] = useState('');
    const [otpSent, setOtpSent] = useState(false);
    const [Err, setError] = useState();
    const [otpVerify, SetOtpVerify] = useState(false);
    const [verifyOtp, setVeriyOtp] = useState();

    const handleInputChange = (e) => {
        const value = e.target.value;
        setMobileNumber(value);

        // Validate mobile number
        if (!/^\d+$/.test(value)) {
            setValidationError('Phone number is not valid');
            setOtpSent(false); // Reset OTP sent status if the mobile number is not valid
        } else {
            setValidationError('');
        }
    };


    useEffect(() => {
        localStorage.setItem('login', 'true')
        if (otpVerify == true) {
            setTimeout(() => {
                window.location.href = "/"
        }, 2000);
        }
    }, [otpVerify])



    const handleBlur = () => {
        const trimmedNumber = mobileNumber.trim();
        // Check if the input is empty on blur
        if (!trimmedNumber) {
            setValidationError('Mobile Number is required');
        } else if (trimmedNumber.length !== 10) {
            setValidationError('Mobile number should be 10 digits');
        } else {
            setValidationError('');
        }
    };

    const handleSendOtp = (event) => {
        event.preventDefault(); // Prevent the default behavior (form submission or link click)

        const loginApiEndpoint = `${baseUrl}/marathon/login`;
        const mobileDetailApiEndpoint = `${baseUrl}/auth/form-details`;
        localStorage.setItem("MobileNumber" , mobileNumber)
        const mobileNumberPayload = {
            mobile_number: mobileNumber,
        };

        axios.get(`${mobileDetailApiEndpoint}/${mobileNumber}`)
            .then(response => {


                // Log statement after successful login
                // console.log('Form-Data API Response:', response.data.data.list);
                // const stringifiedData = JSON.stringify(response);


                // Call the second API after successful login
                if (response.status == 200) {
                    SetOtpVerify(true);

                    localStorage.setItem('Form-Data API Response:', response.data.data.list);
                    setVeriyOtp(response?.data?.code)
                } else {
                    toast.error("Something went wrong!");
                    setError(response.data.msg || 'Form Data Geeting Error!');
                    console.log(response.data.msg);
                }
            })
            .catch(error => {
                console.error('Error GET Form-Data:', error);
            });

        axios.post(loginApiEndpoint, mobileNumberPayload)
            .then(response => {
                if (response.status === 200) {
                    SetOtpVerify(true);

                    toast.success("Login successfully!");
                    localStorage.setItem("data", JSON.stringify(response?.data?.data?.user));

                    console.log(response?.data?.data?.user);
                    // Log statement after successful login
                    console.log('First API Response:', response);
                    localStorage.setItem("MobileNumber", mobileNumber)

                } else {
                    toast.error("Something went wrong!");
                    setError(response.data.msg || 'Error sending OTP. Please try again.');
                    console.log(response.data.msg);
                }
            })
            .catch(error => {
                setError('Error sending OTP. Please try again.');
                console.error('Error sending OTP:', error);
            });


    };




    // const handleVerifyOtp = () => {
    //     console.log(otp, verifyOtp)

    // };

    const isButtonDisabled = !mobileNumber || !!validationError || otpSent || mobileNumber.length !== 10;
    const isOtpInputDisabled = otpSent !== true;

    console.log(Err);

    return (
        <div className='bg-[#F2F2F2] flex flex-auto flex-col h-[100vh]'>
            <div className='grid h-full'>
                <div className='col-span-2 flex flex-col justify-center items-center dark:bg-gray-800' style={{ backgroundColor: "rgb(242, 242, 242)" }}>
                    <div style={{ padding: "2.5rem", backgroundColor: "#fff" }}>
                        <div className='flex justify-center'>
                            <img src="../../Images/Login.png" alt="Logo" width={"160px"} />
                        </div>
                        <div className='text-center mt-9'>
                            <div className='mb-4'>
                                <h3 className='mb-1 font-medium	text-[#000]'>Welcome back!</h3>
                                <p>Please enter your credentials to sign in!</p>
                            </div>
                        </div>
                        <div className="flex flex-col text-left" style={{ marginBottom: '1.75rem' }}>
                            <label className="mb-2">Mobile Number</label>
                            <input
                                type="text"
                                className="input input-md h-11 focus:ring-indigo-900 focus-within:ring-indigo-900 focus-within:border-indigo-900 focus:border-indigo-900"
                                name="mobile_number"
                                placeholder="Mobile Number"
                                value={mobileNumber}
                                onChange={handleInputChange}
                                onBlur={handleBlur}
                                disabled={otpSent} // Disable input if OTP has been sent
                            />
                            {validationError && <p className="text-red-500 mt-1">{validationError} {Err}</p>}
                        </div>
                        {/* <div className='flex flex-col text-left' style={{ marginBottom: '1.75rem' }}>
                            <label className='mb-2'>OTP</label>
                            <OtpInput
                                value={otp}
                                onChange={setOtp}
                                numInputs={6}
                                renderSeparator={<span> </span>}
                                renderInput={(props) => <input {...props} className="Otp_input" disabled={isOtpInputDisabled} />}
                            />
                        </div> */}
                        {/* {otpSent ? <button
                            className={`button bg-indigo-900 hover:bg-indigo-900 active:bg-indigo-900 text-white rounded h-11 px-8 py-2 font-medium w-full`}
                            type='button'
                            onClick={handleVerifyOtp}
                        >
                            Verify OTP
                        </button> : */}
                        <button
                            className={`button bg-indigo-900 hover:bg-indigo-900 active:bg-indigo-900 text-white rounded h-11 px-8 py-2 font-medium w-full ${isButtonDisabled ? 'opacity-50' : ''}`}
                            type='button'
                            onClick={handleSendOtp}
                            disabled={isButtonDisabled}
                        >
                            Login
                        </button>
                        <br /><br />
                        <h6><a href="https://wa.me/+919879208321" style={{ color: "red" }} >Developed By ITFUTURZ</a></h6> 
                        {/* } */}

                    </div>
                    <div>
                    </div>
                </div>
            </div>
        </div>
    );
}
