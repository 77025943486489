import React, { createContext, useContext, useState } from 'react';

const DataContext = createContext();

export const useDataContext = () => {
  return useContext(DataContext);
};

export const DataProvider = ({ children }) => {
  const [dataFromTopGoal, setDataFromTopGoal] = useState(null);
  const [showDetail , setShowDetail] = useState();
  const [profileImg, setProfileImg] = useState();
  return (
    <DataContext.Provider value={{ dataFromTopGoal, setDataFromTopGoal, showDetail , setShowDetail, profileImg, setProfileImg  }}>
      {children}
    </DataContext.Provider>
  );
};
