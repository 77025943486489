import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { CssBaseline, Container, Paper, Box } from "@material-ui/core";

function Protected(props) {
    const { Component } = props;
    const navigate = useNavigate();

    useEffect(() => {
        let login = localStorage.getItem('login');
        if (!login) {
            navigate('/login');
        }
    }, [navigate]);

    return (
        <>
            <CssBaseline />
            <Container component="div" p={4}>
                <Paper component="div" p={3}>
                    {Component && <Component />}
                </Paper>
            </Container>
        </>
    );
}

export default Protected;
